import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/PageLayout.js";
import { mix } from '@theme-ui/color';
import Footer from '../components/footer';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const Box = makeShortcode("Box");
const Divider = makeShortcode("Divider");
const Image = makeShortcode("Image");
const Heading = makeShortcode("Heading");
const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`aboutMe`}</h1>
    <Grid gap={2} columns={[1, '2fr 1fr']} mdxType="Grid">
  <Box mdxType="Box">
    Hey Visitor!👋 My name is Yash Dave, a Front-end developer and a budding Data Science Enthusiast from the town of Vadodara, Gujarat, India. 
    Creating websites that look beautiful and are performant is my passion, and ensuring they are accessible is my mission! 
    <Divider mdxType="Divider" />
    I am a Software Engineer @ <a href="https://deepsource.io/" target="_blank" rel="noopener noreferrer">DeepSource</a>. ♥
    <Divider mdxType="Divider" />
    I love working with Open Source Tech while also contributing to them. 
    I am a proud Mozillian, part of Mozilla Gujarat, and contribute to the DEV Community when I get a chance. 
    Encouraging and helping beginners contribute to Open Source is one of my favorite past-times.
  </Box>
  <Box sx={{
        display: 'flex',
        alignItems: 'center',
        p: 2
      }} mdxType="Box">
    <Image src="../images/yash_512.jpg" alt='Photo depicting myself, Yash Dave' mdxType="Image" />
  </Box>
    </Grid>
    <Divider mdxType="Divider" />
    <Grid gap={2} columns={[1, '1fr 1fr 1fr']} mdxType="Grid">  
    <Box mdxType="Box">   
        <Heading variant='styles.h2' mdxType="Heading">fetchExperience</Heading>
        <Heading variant='styles.h3' mb={0} mdxType="Heading">DeepSource</Heading>
        <Text mdxType="Text">Software Engineer</Text>
        <Text variant='styles.small' mdxType="Text">Aug 2021 - Present</Text>
        <Divider mdxType="Divider" />
        <Heading variant='styles.h3' mb={0} mdxType="Heading">Bitpod</Heading>
        <Text mdxType="Text">Trainee Engineer</Text>
        <Text variant='styles.small' mdxType="Text">Nov 2020 - Jun 2021</Text>
        <Divider mdxType="Divider" />
        <Heading variant='styles.h3' mb={0} mdxType="Heading">Gateway NINtec Pvt. Ltd.</Heading>
        <Text mdxType="Text">Software Intern</Text>
        <Text variant='styles.small' mdxType="Text">Jan 2019 - May 2019</Text>
        <Divider mdxType="Divider" />
        <Heading variant='styles.h3' mb={0} mdxType="Heading">Potent Embedded Systems</Heading>
        <Text mdxType="Text">Software Intern</Text>
        <Text variant='styles.small' mdxType="Text">May 2018 - Jun 2018</Text>
        <Divider mdxType="Divider" />
    </Box>
    <Box mdxType="Box">   
        <Heading variant='styles.h2' mdxType="Heading">fetchVolunteering</Heading>
        <Heading variant='styles.h5' mb={0} sx={{
          color: mix('secondary', 'success', `0.5`)
        }} mdxType="Heading">SICSR ACM Student Chapter</Heading>
        <Text mdxType="Text">Vice Chair</Text>
        <Text variant='styles.small' mdxType="Text">Nov 2019 - Sep 2020</Text>
        <Divider mdxType="Divider" />
        <Heading variant='styles.h5' mb={0} sx={{
          color: mix('secondary', 'success', `0.5`)
        }} mdxType="Heading">Facebook Developer Circles - Pune</Heading>
        <Text mdxType="Text">Moderator</Text>
        <Text variant='styles.small' mdxType="Text">Sep 2019 - Present</Text>
        <Divider mdxType="Divider" />
        <Heading variant='styles.h5' mb={0} sx={{
          color: mix('secondary', 'success', `0.5`)
        }} mdxType="Heading">Computers@NUV</Heading>
        <Text mdxType="Text">Vice President & Publisher</Text>
        <Text variant='styles.small' mdxType="Text">Jun 2017 - Oct 2018</Text>
        <Divider mdxType="Divider" />
        <Heading variant='styles.h5' mb={0} sx={{
          color: mix('secondary', 'success', `0.5`)
        }} mdxType="Heading">Change Vadodara Campaign</Heading>
        <Text mdxType="Text">Student Volunteer</Text>
        <Text variant='styles.small' mdxType="Text">May 2017 - Jun 2017</Text>
        <Divider mdxType="Divider" />
    </Box>
    <Box mdxType="Box">   
        <Heading variant='styles.h2' mdxType="Heading">fetchEducation</Heading>
        <Heading variant='styles.h5' mb={0} mdxType="Heading">Masters in Computer Science - Computer Applications (Data Science)</Heading>
        <Text mdxType="Text">Symbiosis International University</Text>
        <Text variant='styles.small' mdxType="Text">Jun 2019 - Jun 2021</Text>
        <Divider mdxType="Divider" />
        <Heading variant='styles.h5' mb={0} mdxType="Heading">Bachelor of Computer Applications</Heading>
        <Text mdxType="Text">Navrachana University</Text>
        <Text variant='styles.small' mdxType="Text">Jun 2016 - May 2019</Text>
        <Divider mdxType="Divider" />
    </Box>
    </Grid>
    <Footer mdxType="Footer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      